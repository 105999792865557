import * as React from "react";

import Button from "../../components/owner-since/button";

import { useForm } from "react-hook-form";
import * as Broker from "../../utils/broker";
import { navigate } from "gatsby";
import * as Analytics from "../../utils/analytics";

const INPUT_STYLES =
  "rounded w-full text-colors-outer-slate text-sm border-solid border px-5 py-2 placeholder-blue-500 mb-5 md:py-2.5 md:text-lg md:mb-7";

function RadioList({ title, options, name, register, required }) {
  return (
    <ul className="mt-4">
      <p className="text-lg mb-4">{title}</p>
      {options.map(option => {
        return (
          <li key={option} className="mb-2">
            <label className="text-lg">
              <input
                className="mr-3"
                type="radio"
                id={option}
                name={option}
                value={option}
                {...register(name, { required })}
              />
              {option}
            </label>
          </li>
        );
      })}
    </ul>
  );
}

function hasError(err) {
  return err ? "border-red-500" : "border-blue-900";
}

const RequestForm = function () {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const onSubmit = async data => {
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      projectDescriber,
      whenToBuy,
      notes,
      approximateDimensions,
    } = data;

    const name = `${firstName} ${lastName}`;

    const id = window.btoa(data.email);
    try {
      // Add form to database
      var myDescriber = projectDescriber;
      if (!myDescriber) {
        myDescriber = "None of the above";
      }

      await Broker.addDistributionRecord(
        id,
        "designConsult",
        ["list:WDxJgt", "designConsultRequest"],
        {
          name,
          email,
          phoneNumber,
          projectDescriber: myDescriber,
          whenToBuy,
          notes,
          approximateDimensions,
        }
      );

      // Add to email list
      const FORM_NAME = "Design Services Request";
      const LIST_ID = "WDxJgt";

      const res = await Broker.subscribe(
        { email, firstName, lastName },
        LIST_ID,
        FORM_NAME
      );

      // go to confirmation page
      return navigate("/design-services/confirmation");
    } catch (err) {
      console.error(err);
      alert("Unable to submit the request, Please try again.");
    }
  };

  return (
    <form className="mt-10" onSubmit={handleSubmit(onSubmit)}>
      <input
        className={`${INPUT_STYLES} ${hasError(errors.firstName)}`}
        name="name"
        placeholder="First Name *"
        type="text"
        {...register("firstName", { required: "firstName" })}
      />

      <input
        className={`${INPUT_STYLES} ${hasError(errors.lastName)}`}
        name="name"
        placeholder="Last Name *"
        type="text"
        {...register("lastName", { required: "lastName" })}
      />

      <input
        className={`${INPUT_STYLES} ${hasError(errors.email)}`}
        name="email"
        placeholder="Email *"
        type="text"
        {...register("email", { required: true })}
      />
      <input
        className={`${INPUT_STYLES} ${hasError(errors.phoneNumber)}`}
        name="phoneNumber"
        placeholder="Phone Number *"
        type="tel"
        {...register("phoneNumber", { required: true })}
      />
      <RadioList
        title="Which option best describes your project?"
        register={register}
        name="projectDescriber"
        required={false}
        options={[
          "Just purchased a new home",
          "Backyard remodel",
          "Refreshing my outdoor furniture",
          "None of the above",
        ]}
      />
      <div className="mt-10">
        <RadioList
          register={register}
          name={"whenToBuy"}
          required
          title="When do you plan to purchase your outdoor furniture? *"
          options={[
            "Within a week",
            "Within a month",
            "Within three months",
            "Within the next year",
          ]}
        />
      </div>

      <div className="mt-10">
        <p className="text-lg mb-2">
          Describe your project and any notes you'd like to share. *
        </p>
        <p className="text-sm mb-4">
          Please provide any details that may be helpful to our design team.
        </p>
        <textarea
          className={`${INPUT_STYLES} ${hasError(errors.notes)}`}
          rows={5}
          {...register("notes", { required: true })}
        />
      </div>

      <div className="mt-10 mb-3">
        <p className="text-lg mb-2">Approximate Dimensions *</p>
        <p className="text-sm mb-6">
          Please provide dimensions of your outdoor space, even a rough estimate
          will do.
        </p>

        <input
          className={`${INPUT_STYLES} ${hasError(
            errors.approximateDimensions
          )}`}
          name="approximateDimensions"
          type="text"
          {...register("approximateDimensions", { required: true })}
        />
      </div>

      <Button fluid={true}>Submit request</Button>
    </form>
  );
};

export default RequestForm;
