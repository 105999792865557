import * as React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../../components/shared/layout-light";

import FeaturedInLogos from "../../components/design-services/featured-in";
import RequestForm from "../../components/design-services/request-form";

export const query = graphql`
  query {
    image: file(relativePath: { eq: "design-services/request-image.png" }) {
      childImageSharp {
        sizes(maxWidth: 1340, maxHeight: 2018) {
          ...GatsbyImageSharpSizes
        }
      }
    }
  }
`;

const DesignServicesRequestPage = function ({ data }) {
  return (
    <div className="text-colors-outer-slate">
      <Layout homeUrl="/design-services">
        <div className="content-container-max mt-16 pt-2">
          <div className="flex flex-col justify-between md:flex-row">
            <div className="order-2 md:order-1 md:w-5/12 md:pr-6">
              <h1 className="text-4xl leading-snug">
                Request a free design
                <br />
                consultation
              </h1>
              <p className="text-xl mt-7 leading-normal">
                From minor space planning questions to complete remodels.
                Completely free with no obligation to buy.
              </p>
              <RequestForm />
            </div>
            <div className="order-1 w-full h-96 overflow-hidden mb-10 md:h-auto md:mb-0 md:order-2 md:w-6/12 md:pl-6">
              <div>
                <Img
                  fluid={data.image.childImageSharp.sizes}
                  alt="Try an Outer Sofa at home"
                />
              </div>
            </div>
          </div>
          <FeaturedInLogos />
        </div>
      </Layout>
    </div>
  );
};

export default DesignServicesRequestPage;
